import React from 'react';
import { compose } from 'redux';
import { DragSource } from 'react-dnd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../Icon/Icon';
import AbstractItemView from './AbstractItemView';
import MediaContextMenu from './MediaContextMenu';
import { BbIconButton } from '../../@browsbox-ui';
import { useDidDraggedRef } from '../../DragAndDrop/hooks';

const propTypes = {
  id: PropTypes.number.isRequired,
  thumbnail: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  fileExtension: PropTypes.string.isRequired,
  updatedOn: PropTypes.string,
  resolution: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
};

const defaultProps = {
  resolution: '',
  updatedOn: '',
  isSelected: false,
  original: '',
  onDoubleClick: () => {
  },
};

const asDate = (date) => {
  if (Number.isNaN(Date.parse(date))) {
    return '';
  }
  return (new Date(date)).toLocaleDateString();
};

const MediaGridViewItem = (props) => {
  const {
    id,
    fileExtension,
    filename,
    isSelected,
    resolution,
    updatedOn,
    thumbnail,
    onRename,
    onDelete,
    onDownload,
    onView,
    onMove,
    connectDragSource,
    isDragging,
  } = props;

  const [open, setOpen] = React.useState(false);
  const didDragged = useDidDraggedRef(props.isDragging);

  const showMenu = () => setOpen(true);
  const hideMenu = () => setOpen(false);

  const handleClick = (event) => {
    if (!didDragged.current && props.onClick) {
      props.onClick(event);
    }
  };

  const isVector = thumbnail && thumbnail.endsWith('svg');

  return connectDragSource(
    <div
      className={classNames(
        'o-bb-media',
        { 'is-dragging': isDragging },
      )}
      onMouseLeave={hideMenu}
    >
      <div
        className="o-bb-media__image"
        onClick={handleClick}
      >
        <img
          src={thumbnail}
          alt=""
          className={classNames(
            'o-bb-media__img',
            { 'o-bb-media__img--vector': isVector },
          )}
          draggable="false"
        />
        <div className="o-bb-media__mask" />
        {isSelected && (
          <div className="o-bb-media__active">
            <Icon name="check" style={{ fill: 'currentColor' }} />
          </div>
        )}
      </div>
      <div className="o-bb-media__footer">
        <h2 className="o-bb-media__title">{filename}{fileExtension}</h2>
        <div className="o-bb-media__meta">
          {asDate(updatedOn)} <span>|</span> {resolution}
        </div>
        <BbIconButton onClick={showMenu} className="o-bb-media__action">
          <Icon name="context-menu" />
        </BbIconButton>
        {open && (
          <MediaContextMenu
            mediaId={id}
            onRename={onRename}
            onRemove={onDelete}
            onDownload={onDownload}
            onView={onView}
            onMove={onMove}
            onClose={hideMenu}
          />
        )}
      </div>
    </div>,
  );
};

MediaGridViewItem.propTypes = propTypes;
MediaGridViewItem.defaultProps = defaultProps;

const MediaGridItem = props => (
  <AbstractItemView
    {...props}
    component={MediaGridViewItem}
  />
);

const dndSource = {
  beginDrag: props => ({
    dndType: 'MEDIA_ITEM',
    name: props.filename,
    id: props.id,
    thumbnail: props.thumbnail,
    extension: props.fileExtension,
  }),
};

const dropSourceCollect = (connectDnd, monitor) => ({
  connectDragSource: connectDnd.dragSource(),
  isDragging: monitor.isDragging(),
  clientOffset: monitor.getClientOffset(),
});

const decorate = compose(
  DragSource('MEDIA_ITEM', dndSource, dropSourceCollect),
);

export default decorate(MediaGridItem);
