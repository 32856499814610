/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { SortableContainer } from 'react-sortable-hoc';
import ListItem from './ListItem';
import i18n from '../../../../internationalization/i18n';
import useWindowSize from '../../../../tools/use-window-size';
import CircleLoader from '../../../Loader/CircleLoader';
import Icon from '../../../Icon/Icon';
import { baseModuleSetSortBy } from '../../../../actions/baseModuleItems';
import SkeletonLoader from '../../../Loader/SkeletonLoader';
import { selectIsMultiLanguage } from '../../../../selectors';

const SortableList = SortableContainer(
  ({
    items, columns, style, sortingEnabled, isMultiLanguage,
  }) => {
    const [expanded, setExpanded] = React.useState(null);

    const toggleExpand = React.useCallback((item) => {
      setExpanded(prev => (prev && (prev === item.id) ? null : item.id));
    }, [setExpanded]);

    const isExpanded = item => expanded && expanded === item.id;

    return (
      <div className="c-bb-table__wrapper c-bb-table__wrapper--scrollable" style={style}>
        {items.map((item, index) => (
          <ListItem
            index={index}
            key={item.id}
            item={item}
            columns={columns}
            onExpand={toggleExpand}
            isExpanded={isExpanded(item)}
            sortingEnabled={sortingEnabled}
            isMultiLanguage={isMultiLanguage}
          />
        ))}
      </div>
    );
  },
);

const SortableLabel = ({
  label, active, asc, onClick,
}) => (
  <div className="c-bb-table__sortable__label" onClick={onClick}>
    {label}
    {active && (
      <div className="c-bb-table__sortable__icon">
        <Icon small name={asc ? 'arrow-up' : 'arrow-down'} />
      </div>
    )}
  </div>
);

SortableLabel.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  asc: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ItemsSkeletonLoader = () => (
  <div style={{ margin: '0 48px' }}>
    <SkeletonLoader height="48px" />
    <SkeletonLoader height="40px" />
    <SkeletonLoader height="40px" />
    <SkeletonLoader height="40px" />
    <SkeletonLoader height="40px" />
    <SkeletonLoader height="40px" />
    <SkeletonLoader height="40px" />
    <SkeletonLoader height="40px" />
  </div>
);

const BaseModuleItemsList = ({
  columns,
  items,
  onSortEnd,
  isLoading,
  sortingEnabled,
  setSortBy,
  sortableField,
  sortableDirection,
  isMultiLanguage,
}) => {
  const { height: winHeight } = useWindowSize();
  const tableRef = React.useRef();
  const headerRef = React.useRef();
  const containerRef = React.useRef();
  const [height, setHeight] = React.useState();

  React.useLayoutEffect(() => {
    const container = containerRef.current;
    const header = headerRef.current;

    if (container && header) {
      const computedStyle = window.getComputedStyle(header);
      const headerHeight = header.clientHeight + parseInt(computedStyle.marginBottom, 10);
      const extraSpace = 16;

      setHeight(container.clientHeight - headerHeight - extraSpace);
    }

    return undefined;
  }, [containerRef.current, headerRef.current, winHeight]);

  const handleSortChange = (field, asc) => {
    setSortBy({ field, direction: asc ? 'asc' : 'desc' });
  };

  return (
    <div className="c-bb-base-module-list-container">
      <div ref={containerRef} className="c-bb-base-module-items-list">
        {isLoading && items.length === 0 ? (
          <ItemsSkeletonLoader />
        ) : (
          <div ref={tableRef} className="c-bb-table c-bb-table--basemodules items-list-container">
            <div ref={headerRef} className="c-bb-table__head c-bb-table__head__colored">
              <div className="c-bb-table__tr">
                <div className="c-bb-table__td c-bb-table__td--expand-button" style={{ position: 'relative' }}>
                  {isLoading ? (<CircleLoader small width={24} height={24} />) : null}
                </div>
                <div className={classNames('c-bb-table__td', 'c-bb-table__td--primary')}>
                  {sortingEnabled ? (
                    i18n.t('BASE_MODULE.title')
                  ) : (
                    <SortableLabel
                      label={i18n.t('BASE_MODULE.title')}
                      onClick={() => handleSortChange('title', sortableDirection !== 'asc')}
                      asc={sortableDirection === 'asc'}
                      active={sortableField === 'title'}
                    />
                  )}
                </div>
                {columns.map(column => (
                  <div key={column.id} className="c-bb-table__td">
                    {(!sortingEnabled && column.sortable) ? (
                      <SortableLabel
                        label={column.originalLabel}
                        onClick={() => handleSortChange(column.field, sortableDirection !== 'asc')}
                        asc={sortableDirection === 'asc'}
                        active={sortableField === column.field}
                      />
                    ) : column.originalLabel}
                  </div>
                ))}
                <div className="c-bb-table__td c-bb-table__td--actions">&nbsp;</div>
              </div>
            </div>
            <SortableList
              style={{ height }}
              helperContainer={() => tableRef.current}
              helperClass="c-bb-base-module-list-sortable-helper"
              lockAxis="y"
              useDragHandle
              columns={columns}
              items={items}
              onSortEnd={onSortEnd}
              sortingEnabled={sortingEnabled}
              isMultiLanguage={isMultiLanguage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

BaseModuleItemsList.propTypes = {
  items: PropTypes.array.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sortingEnabled: PropTypes.bool.isRequired,
  setSortBy: PropTypes.func.isRequired,
  sortableField: PropTypes.string,
  sortableDirection: PropTypes.string,
  isMultiLanguage: PropTypes.bool.isRequired,
};

BaseModuleItemsList.defaultProps = {
  sortableField: null,
  sortableDirection: null,
};

const selectVisibleColumns = (state, currentModule) => {
  const columns = currentModule.columns || [];

  return columns.map(({ field, sortable }) => {
    const columnField = currentModule.fields.find(item => item.internal_name === field);

    if (!columnField) {
      return null;
    }

    return {
      ...columnField,
      field,
      sortable,
    };
  }).filter(Boolean);
};

const mapStateToProps = (state, props) => ({
  columns: selectVisibleColumns(state, props.currentModule),
  sortableField: state.baseModuleItems.sortableField,
  sortableDirection: state.baseModuleItems.sortableDirection,
  isMultiLanguage: selectIsMultiLanguage(state),
});

const mapDispatchToProps = {
  setSortBy: baseModuleSetSortBy,
};

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(BaseModuleItemsList),
);
