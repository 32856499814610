/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Gateway } from '../Gateway';
import Icon from '../Icon/Icon';
import { getDefaultModuleList } from '../../selectors';
import {
  openBaseModuleView,
  getBaseModuleList,
} from '../../actions/baseModule';
import { BbPopup } from '../@browsbox-ui';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import SuperAdminOnly from '../AcessControll/SuperAdminOnly';
import i18n from '../../internationalization/i18n';

const NavItemActions = ({ id, onContentClick, onClose }) => (
  <Gateway into="popup">
    <BbPopup
      target={`base-module-entity-actions-${id}`}
      onClose={onClose}
      onMouseLeave={onClose}
      placement="right top"
    >
      <div className="o-bb-base-module__navigation__actions">
        <a className="o-bb-popup__action" onClick={onContentClick}>
          <FontAwesomeIcon name="far fa-sliders-h" className={classNames('o-bb-popup__action__icon')} />
          {i18n.t('CONTENT.configure')}
        </a>
      </div>
    </BbPopup>
  </Gateway>
);

const BaseModuleNavigation = ({
  modules, isSuperAdmin, currentModule, ...props
}) => {
  const history = useHistory();
  const [active, setActive] = React.useState(null);

  React.useEffect(
    () => () => {
      if (window && window.location.hash.startsWith('#/base-module')) {
        window.location.hash = '';
      }
    },
    [],
  );

  const isModuleActive = React.useCallback(
    module => currentModule && module.internal_name === currentModule.internal_name,
    [currentModule],
  );

  const showModuleItems = (module) => {
    history.push(`/items/${module.internal_name}`);
    props.openBaseModuleView();
    setActive(null);
  };

  const handleClick = (module) => {
    showModuleItems(module);
  };

  const handleAddEntity = () => {
    history.push('/create');
    props.openBaseModuleView();
  };

  const handleActionsClick = (module) => {
    setActive(prev => (prev ? null : module));
  };

  const handleContentClick = (module) => {
    props.openBaseModuleView();
    history.push(`/edit/${module.internal_name}`);
  };

  return (
    <div className="o-bb-base-module__content">
      <SuperAdminOnly>
        <button
          type="button"
          className="o-bb-base-module__add-entity"
          onClick={handleAddEntity}
        >
          <Icon name="add" />
          <span>{i18n.t('BASE_MODULE.addModule')}</span>
        </button>
      </SuperAdminOnly>
      <ul className="o-bb-base-module__navigation">
        {modules.map(module => (
          <li
            key={module.id}
            className={classNames(
              'o-bb-base-module__navigation__item',
              { 'o-bb-base-module__navigation__item--active': isModuleActive(module) },
            )}
          >
            <a onClick={() => handleClick(module)}>
              <FontAwesomeIcon name={module.icon || 'fak fa-module'} className="icon icon--entity" />
              <span className="label">{module.label}</span>
            </a>
            {isSuperAdmin && (
              <Icon
                id={`base-module-entity-actions-${module.id}`}
                className="o-bb-base-module__navigation__item__more"
                name="context-menu"
                onClick={() => handleActionsClick(module)}
              />
            )}
            {isSuperAdmin && (module === active) && (
              <NavItemActions
                id={module.id}
                onClose={() => setActive(null)}
                onContentClick={() => handleContentClick(module)}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    pages: {
      settings: {
        isSuperAdmin,
      },
    },
  } = state;

  return {
    currentScreen: state.baseModule.currentScreen,
    currentModule: state.baseModule.currentModule,
    domain: state.global.websiteSettings.domain,
    baseModuleLang: state.baseModule.lang,
    modules: getDefaultModuleList(state),
    isSuperAdmin,
  };
};

const mapDispatchToProps = {
  openBaseModuleView,
  getBaseModuleList,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseModuleNavigation);
