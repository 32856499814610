/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import * as i18n from 'i18next';
import { connect } from 'react-redux';
import { Gateway } from '../../../Gateway';
import { BbPopup } from '../../../@browsbox-ui';
import FontAwesomeIcon from '../../../Icon/FontAwesomeIcon';

const isDisabled = (item, langCode) => {
  if (item.language === langCode) {
    return true;
  }

  return Array.from(item.translations).some(i => i.language === langCode);
};

const ContextMenu = ({
  item, onClose, onEdit, onMove, onDelete, onClone, onPreview, allowClone, languages,
}) => {
  const availableCopies = allowClone
    ? languages
      .map(lang => ({
        code: lang.code,
        isDisabled: isDisabled(item, lang.code),
      }))
    : [];
  const isMultilang = languages.length > 1;

  return (
    <Gateway into="popup">
      <BbPopup
        target={`base-module-item-actions-${item.id}`}
        onClose={onClose}
        onMouseLeave={onClose}
        placement="left top"
        className="o-bb-base-module__item__actions"
      >
        <a className="o-bb-popup__action" onClick={onEdit}>
          <FontAwesomeIcon name="fas fa-pen" className={classNames('o-bb-popup__action__icon')} />
          {i18n.t('BASE_MODULE.adjust')}
        </a>
        {onPreview && (
          <a className="o-bb-popup__action" onClick={onPreview}>
            <FontAwesomeIcon name="fas fa-eye" className={classNames('o-bb-popup__action__icon')} />
            {i18n.t('BASE_MODULE.preview')}
          </a>
        )}
        <hr className="o-bb-popup__action__divider" />
        {isMultilang && (
          <a className="o-bb-popup__action" onClick={onMove}>
            <FontAwesomeIcon name="fas fa-layer-group" className={classNames('o-bb-popup__action__icon')} />
            {i18n.t('BASE_MODULE.groupWith')}
          </a>
        )}
        {(allowClone && availableCopies.length > 1) && (
          <>
            {availableCopies.map(lang => (
              <a
                className={classNames(
                  'o-bb-popup__action',
                  { 'o-bb-popup__action--disabled': lang.isDisabled },
                )}
                key={lang.code}
                onClick={lang.isDisabled ? undefined : () => onClone(lang.code)}
              >
                <FontAwesomeIcon name="far fa-copy" className={classNames('o-bb-popup__action__icon')} />
                {i18n.t('CONTENT.cloneTo')} &rarr; {lang.code}
              </a>
            ))}
          </>
        )}
        <hr className="o-bb-popup__action__divider" />
        <a className="o-bb-popup__action" onClick={onDelete}>
          <FontAwesomeIcon name="fas fa-trash" className={classNames('o-bb-popup__action__icon')} />
          {i18n.t('BASE_MODULE.delete')}
        </a>
      </BbPopup>
    </Gateway>
  );
};

const ConnectedContextMenu = connect(state => ({
  languages: Object.values(state.entities.languages),
}))(ContextMenu);

export default ConnectedContextMenu;
