/* eslint-disable react/forbid-prop-types,react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import classNames from 'classnames';
import ImageValue from './ColumnValue/Image';
import TextValue from './ColumnValue/Text';
import UrlValue from './ColumnValue/Url';
import DateValue from './ColumnValue/Date';
import BooleanValue from './ColumnValue/Boolean';
import EmailValue from './ColumnValue/Email';
import ContextMenu from './ContextMenu';
import { BbIconButton } from '../../../@browsbox-ui';
import AbstractItemView from './AbstractItemView';
import ItemInfoPopup from './ItemInfoPopup';
import FontAwesomeIcon from '../../../Icon/FontAwesomeIcon';

const DragHandle = SortableHandle(() => (
  <div className="c-bb-item__sortable-handle">
    <span><FontAwesomeIcon name="far fa-bars" /></span>
  </div>
));

const componentMap = {
  image: ImageValue,
  url: UrlValue,
  text: TextValue,
  longtext: TextValue,
  number: TextValue,
  phone_number: TextValue,
  select: TextValue,
  range: TextValue,
  date: DateValue,
  boolean: BooleanValue,
  email: EmailValue,
};

const ColumnValue = ({ column, value }) => {
  if (column.type in componentMap) {
    const Component = componentMap[column.type];

    return <Component column={column} value={value} />;
  }

  return null;
};

ColumnValue.propTypes = {
  column: PropTypes.object.isRequired,
  value: PropTypes.any,
};

ColumnValue.defaultProps = {
  value: null,
};

const mapFieldValues = (columns, item) => {
  const values = {};

  columns.forEach((column) => {
    const value = item.field_values.find(i => i.field.internal_name === column.internal_name);

    if (value) {
      values[column.internal_name] = value.value;
    }
  });

  return values;
};

const ItemView = (props) => {
  const {
    item,
    columns,
    onEdit,
    onRead,
    onDelete,
    onPublish,
    onPreview,
    onMove,
    onClone,
    allowClone,
    isMultiLanguage,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const values = mapFieldValues(columns, item);

  const openInfoPopup = () => {
    onRead(item.id);
    setInfoOpen(prev => !prev);
  };

  const published = item.route.publishable && item.route.published;
  const { publishable } = item.route;

  return (
    <>
      <div className={classNames('c-bb-table__td', 'c-bb-table__td--primary')}>
        <div className="items-list__label">
          {isMultiLanguage && (
            <span className="items-list__language">{item.language.toUpperCase()}</span>
          )}
          <span className="c-bb-item__title" onClick={onEdit}>
            {item.title}
          </span>
        </div>
      </div>
      {columns.map(column => (
        <div key={column.id} className={classNames('c-bb-table__td', 'c-bb-table__td--secondary', `c-bb-table__td--${column.type}`)}>
          <div className="c-bb-item__column_value">
            <ColumnValue column={column} value={values[column.internal_name]} />
          </div>
        </div>
      ))}
      <div className="c-bb-table__td c-bb-table__td--actions">
        <BbIconButton medium onClick={openInfoPopup}>
          <span id={`base-module-item-info-${item.id}`}>
            <FontAwesomeIcon name="far fa-info-circle" />
          </span>
          {infoOpen && (
            <ItemInfoPopup
              item={item}
              onClose={() => setInfoOpen(false)}
            />
          )}
        </BbIconButton>
        <BbIconButton medium onClick={() => onPublish(item)} disabled={!publishable} className={classNames('c-bb-icon-button--publish', { 'is-published': published })}>
          <FontAwesomeIcon name={publishable && published ? 'far fa-eye' : 'far fa-eye-slash'} />
        </BbIconButton>
        <BbIconButton medium onClick={() => setOpen(true)}>
          <span id={`base-module-item-actions-${item.id}`}>
            <FontAwesomeIcon name="far fa-ellipsis-v" />
          </span>
          {open && (
            <ContextMenu
              item={item}
              onClose={() => setOpen(false)}
              onEdit={onEdit}
              onDelete={onDelete}
              onMove={onMove}
              onClone={onClone}
              onPreview={onPreview}
              allowClone={allowClone}
            />
          )}
        </BbIconButton>
      </div>
    </>
  );
};

const ListItem = SortableElement(({
  item,
  isExpanded,
  onExpand,
  columns,
  sortingEnabled,
  isMultiLanguage,
}) => (
  <div className="c-bb-table__row">
    {sortingEnabled && (
      <DragHandle />
    )}
    <div className={classNames(
      'c-bb-table__tr',
      { 'is-expanded': isExpanded },
    )}
    >
      <div className="c-bb-table__td c-bb-table__td--expand-button">
        {item.translations && item.translations.length > 0 && isMultiLanguage && (
          <BbIconButton small onClick={() => onExpand(item)}>
            <FontAwesomeIcon name="fas fa-chevron-right" className="c-bb-table__icon c-bb-table__icon--expand" />
          </BbIconButton>
        )}
      </div>
      <AbstractItemView
        item={item}
        columns={columns}
        component={ItemView}
        isMultiLanguage={isMultiLanguage}
        allowClone
      />
    </div>
    {isExpanded && item.translations.length > 0 && item.translations.map(translation => (
      <div className="c-bb-table__tr" key={translation.id}>
        <div className="c-bb-table__td c-bb-table__td--expand-button" />
        <AbstractItemView
          parentId={item.id}
          item={translation}
          columns={columns}
          component={ItemView}
          isMultiLanguage={isMultiLanguage}
        />
      </div>
    ))}
  </div>
));

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  columns: PropTypes.array.isRequired,
  onExpand: PropTypes.func.isRequired,
  sortingEnabled: PropTypes.bool.isRequired,
  isMultiLanguage: PropTypes.bool.isRequired,
};

export default React.memo(ListItem);
