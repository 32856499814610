import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import TinyMCE from 'react-tinymce';
import 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/link';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/table';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/code';
import './plugins/stylebuttons';
import './plugins/preventDelete';
import './plugins/preventTypeOutside';
import './plugins/preventEnterKey';
// import './plugins/disableEmojis';
import './localization/langs/nl';
import './localization/langs/en_GB';
import './localization/langs/pl';
import './localization/langs/fr_FR';
import mediaLinkButton from './buttons/mediaLinkButton';
import linkButton from './buttons/linkButton';
import { editorUpdate } from '../../actions/contentEditor';
import i18n, { currentBrowsboxLanguage } from '../../internationalization/i18n';
import Media from '../MediaManager/Media';
import Links from '../Links/Links';
import LanguageContext from '../Languages/LanguageContext';

require('tinymce/skins/lightgray/skin.min.css');
require('tinymce/skins/lightgray/content.inline.min.css');

const propTypes = {
  adminAssetPath: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.string,
  editorUpdate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  themeEditorAvailable: PropTypes.bool.isRequired,
  type: PropTypes.string,
  defaultLanguage: PropTypes.object,
  shouldBroadCastUpdate: PropTypes.bool,
  extraOptions: PropTypes.object,
};

const defaultProps = {
  children: null,
  className: '',
  content: '',
  type: 'text',
  defaultLanguage: {
    code: 'nl',
  },
  shouldBroadCastUpdate: true,
  extraOptions: {},
};

const defaultColors = [
  '000000', 'Black',
  'f0f0f0', 'Gray',
  'ffffff', 'White',
];
const themeEditorPlugins = ['textcolor'];

const tinymceConfigDefault = {
  menubar: false,
  statusbar: false,
  inline: true,
  convert_urls: 0,
  paste_filter_drop: false,
  remove_script_host: 0,
  textcolor_map: defaultColors,
  skin: false,
};

const config = {
  title: (themeEditorAvailable, adminAssetPath, showMediaLinkModal, showLinkModal, commitChange) => {
    const plugins = ['stylebuttons', 'link', 'paste']
      .concat(themeEditorAvailable ? themeEditorPlugins : [])
      .join(' ');
    const toolbar = ['bold italic | formatselect | alignleft aligncenter alignright | linkPicker unlink'];
    if (themeEditorAvailable) {
      toolbar.push('| forecolor');
    }

    return {
      ...tinymceConfigDefault,
      plugins,
      toolbar: toolbar.join(' '),
      media_strict: true,
      valid_elements: '@[style],h1,a[!href|target],strong/b,em,p[class],span[!style],br',
      valid_classes: {
        p: 'o-tagline',
      },
      valid_styles: {
        '*': 'text-align',
        span: 'color',
      },
      keep_styles: false,
      formats: {
        tagline_format: {
          block: 'p',
          attributes: {
            class: 'o-tagline',
          },
          exact: true,
        },
        p_format: {
          block: 'p',
          attributes: {
            class: '',
          },
          exact: true,
        },
        h1_format: {
          block: 'h1',
          attributes: {
            class: '',
          },
          exact: true,
        },
      },
      preview_styles: 'font-family font-size font-weight font-style text-decoration text-transform background-color border border-radius outline text-shadow',
      block_formats: `${i18n.t('EDITOR.Tagline')}=tagline_format;${i18n.t('EDITOR.Paragraf')}=p_format;${i18n.t('EDITOR.Title')}=h1_format`,
      setup: (editor) => {
        editor.addButton('linkPicker', linkButton(editor, adminAssetPath, showLinkModal, commitChange));
      },
    };
  },

  subtitle: (themeEditorAvailable, adminAssetPath, showMediaLinkModal, showLinkModal, commitChange) => {
    const plugins = ['stylebuttons', 'link', 'paste']
      .concat(themeEditorAvailable ? themeEditorPlugins : [])
      .join(' ');
    const toolbar = ['bold italic | formatselect | alignleft aligncenter alignright | linkPicker unlink'];
    if (themeEditorAvailable) {
      toolbar.push('| forecolor');
    }

    return {
      ...tinymceConfigDefault,
      plugins,
      toolbar: toolbar.join(' '),
      media_strict: true,
      valid_elements: '@[style],h2,a[!href|target],strong/b,em,p[class],span[!style],br',
      valid_classes: {
        p: 'o-tagline',
      },
      valid_styles: {
        '*': 'text-align',
        span: 'color',
      },
      keep_styles: false,
      formats: {
        tagline_format: {
          block: 'p',
          attributes: {
            class: 'o-tagline',
          },
          exact: true,
        },
        p_format: {
          block: 'p',
          attributes: {
            class: '',
          },
          exact: true,
        },
        h2_format: {
          block: 'h2',
          attributes: {
            class: '',
          },
          exact: true,
        },
      },
      block_formats: `${i18n.t('EDITOR.Tagline')}=tagline_format;${i18n.t('EDITOR.Paragraf')}=p_format;${i18n.t('EDITOR.Title')}=h2_format`,
      setup: (editor) => {
        editor.addButton('linkPicker', linkButton(editor, adminAssetPath, showLinkModal, commitChange));
      },
    };
  },
  text: (themeEditorAvailable, adminAssetPath, showMediaLinkModal, showLinkModal, commitChange) => {
    const plugins = ['stylebuttons', 'link', 'paste', 'autoresize', 'table', 'lists']
      .concat(themeEditorAvailable ? themeEditorPlugins : [])
      .join(' ');
    const toolbar = ['bold italic | formatselect | alignleft aligncenter alignright | bullist numlist | outdent indent | insertMedia linkPicker unlink | table'];
    if (themeEditorAvailable) {
      toolbar.push('| forecolor');
    }
    return {
      ...tinymceConfigDefault,
      plugins,
      toolbar: toolbar.join(' '),
      valid_elements: '@[style],a[!href|target],strong/b,ul,ol,li,em,h3,blockquote[style],p[class],table[class|align|style|border|cellpadding|cellspacing],caption,tr,td[style|colspan],th[style|colspan],span[!style],br',
      valid_classes: {
        p: 'o-tagline',
        table: [
          'table--align-left',
          'table--align-center',
          'table--align-right',
        ],
      },
      table_class_list: [
        { title: 'Left', value: 'table--align-left' },
        { title: 'Center', value: 'table--align-center' },
        { title: 'Right', value: 'table--align-right' },
      ],
      keep_styles: false,
      valid_styles: {
        '*': 'text-align',
        span: 'color',
        th: 'width',
        td: 'width',
        table: 'height,width,border-collapse,border-style,border-color,background-color,margin-left,margin-right,float',
        p: 'padding-left',
        li: 'list-style-type',
      },
      formats: {
        alignleft: {
          selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,blockquote',
          styles: {
            textAlign: 'left',
          },
        },
        aligncenter: {
          selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,blockquote',
          styles: {
            textAlign: 'center',
          },
        },
        alignright: {
          selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,blockquote',
          styles: {
            textAlign: 'right',
          },
        },
        alignjustify: {
          selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,blockquote',
          styles: {
            textAlign: 'justify',
          },
        },
        tagline_format: {
          block: 'p',
          attributes: {
            class: 'o-tagline',
          },
          exact: true,
        },
        blockquote_format: {
          block: 'blockquote',
          exact: true,
        },
        p_format: {
          block: 'p',
          attributes: {
            class: '',
          },
          exact: true,
        },
        h3_format: {
          block: 'h3',
          attributes: {
            class: '',
          },
          exact: true,
        },
      },
      block_formats: `${i18n.t('EDITOR.Tagline')}=tagline_format;${i18n.t('EDITOR.Paragraf')}=p_format;${i18n.t('EDITOR.Title')}=h3_format;${i18n.t('EDITOR.Quote')}=blockquote_format`,
      setup: (editor) => {
        editor.addButton('insertMedia', mediaLinkButton(editor, adminAssetPath, showMediaLinkModal, commitChange));
        editor.addButton('linkPicker', linkButton(editor, adminAssetPath, showLinkModal, commitChange));
      },
    };
  },

  button: (themeEditorAvailable, adminAssetPath, showMediaLinkModal, showLinkModal, commitChange) => {
    const plugins = ['link', 'paste', 'preventTypeOutside', 'preventDelete', 'preventEnterKey']
      .concat(themeEditorAvailable ? themeEditorPlugins : [])
      .join(' ');
    const toolbar = ['bold italic | insertMedia linkPicker'];
    if (themeEditorAvailable) {
      toolbar.push('| forecolor');
    }
    return {
      ...tinymceConfigDefault,
      plugins,
      forced_root_block: false,
      toolbar: toolbar.join(' '),
      valid_elements: 'a[href|target|class],strong/b,em',
      inline_boundaries_selector: 'a[href],b,i,em,strong',
      valid_children: 'a[b|i|em|strong|#text],-em[a],-strong[a],-b[a],-i[a],-div[#text]',
      paste_word_valid_elements: 'b,strong,i,em',
      paste_as_text: true,
      paste_preprocess: (plugin, args) => {
        // eslint-disable-next-line no-param-reassign
        args.content = String(args.content)
          // removes all tags
          .replace(/(<([^>]+)>)/ig, '')
          // removes all line-breaks
          .replace(/&lt;/g, '')
          .replace(/&gt;/g, '')
          .replace(/\r?\n|\r/g, '');
      },
      setup: (editor) => {
        editor.addButton('insertMedia', mediaLinkButton(editor, adminAssetPath, showMediaLinkModal, commitChange));
        editor.addButton('linkPicker', linkButton(editor, adminAssetPath, showLinkModal, commitChange));
      },
      init_instance_callback: (editor) => {
        // eslint-disable-next-line no-param-reassign
        editor.pasteAsPlainText = true;
        const targetElement = editor.targetElm;

        if (!targetElement) {
          return;
        }

        const button = targetElement.querySelector('a');
        if (button) {
          button.setAttribute('data-mce-href', button.getAttribute('href') || '');
          // eslint-disable-next-line no-param-reassign
          editor.bbHiperlink = button.getAttribute('href') || '';
        }

        const contentHolder = document.createElement('div');
        contentHolder.innerHTML = editor.getContent();
        const link = contentHolder.querySelector('a');
        if (link) {
          link.innerHTML = '&nbsp';
          link.setAttribute('href', '');
          link.setAttribute('data-mce-href', '');
        }

        editor.on('keyup', () => {
          const content = editor.getContent();
          if (!content || content.length <= 1) {
            link.setAttribute('href', editor.bbHiperlink);
            editor.setContent(contentHolder.innerHTML);
          }
        });

        editor.on('change', (event) => {
          if (!editor.targetElm) {
            event.preventDefault();
            return false;
          }

          // sometimes tinyMCE creates multiple 'a' elements when pasting, this is not valid behavior
          const additionalAnchors = Array.from(editor.targetElm.querySelectorAll('a')).slice(1);

          if (additionalAnchors) {
            additionalAnchors.forEach(item => item.remove());
          }

          return false;
        });

        editor.on('paste', (event) => {
          const target = event.currentTarget;
          const pastedHtml = target.innerHTML;
          const div = document.createElement('div');
          div.innerHTML = pastedHtml;
          const anchor = div.querySelector('a');

          if (!anchor) {
            const wrapper = document.createElement('div');
            wrapper.innerHTML = contentHolder.innerHTML;
            const newAnchor = wrapper.querySelector('a');
            newAnchor.innerHTML = pastedHtml;
            newAnchor.setAttribute('href', editor.bbHiperlink);
            editor.setContent(wrapper.innerHTML);
          }
        });
      },
    };
  },

  html: (themeEditorAvailable, adminAssetPath, showMediaLinkModal, showLinkModal, commitChange) => {
    const plugins = ['stylebuttons', 'link', 'paste', 'autoresize', 'table', 'lists', 'code']
      .concat(themeEditorAvailable ? themeEditorPlugins : [])
      .join(' ');
    const toolbar = ['code | bold italic blockquote style-h3 | alignleft aligncenter alignright | bullist numlist | insertMedia linkPicker unlink | table'];
    if (themeEditorAvailable) {
      toolbar.push('| forecolor');
    }
    return {
      ...tinymceConfigDefault,
      plugins,
      toolbar: toolbar.join(' '),
      valid_elements: 'a[!href|!target],strong/b,div,br,td[class],th[class],tr[class],table[class],ul,ol,li,em,h3[style],h4[style],blockquote,p,table[style],tr[style|class],td[style|colspan|class],th[style|colspan|class],span',
      setup: (editor) => {
        editor.addButton('insertMedia', mediaLinkButton(editor, adminAssetPath, showMediaLinkModal, commitChange));
        editor.addButton('linkPicker', linkButton(editor, adminAssetPath, showLinkModal, commitChange));
      },
    };
  },

};

// Remove tinymce specific attributes from the tinymce 'raw' format
export const sanatizeHtml = (html) => {
  const serializer = new window.tinymce.dom.Serializer({
    fix_list_elements: true,
  });
  // This rule seems to strip out all tinymce attributes
  serializer.setRules('*[*]');
  // Serializer requires dom nodes
  const raw = document.createElement('raw');
  raw.innerHTML = html;
  const serialize = [];
  if (raw.hasChildNodes()) {
    for (let i = 0; i < raw.children.length; i += 1) {
      serialize.push(serializer.serialize(raw.children[i]));
    }
  }
  return serialize.join('');
};

class Editor extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.onEditorBlur = this.onEditorBlur.bind(this);
    this.onShowMediaLink = this.onShowMediaLink.bind(this);
    this.onShowLink = this.onShowLink.bind(this);
    this.commitChange = this.commitChange.bind(this);
    this.onEditorKeydown = debounce(this.onEditorKeydown.bind(this), 1000, { leading: true });
    this.onEditorChange = debounce(this.onEditorChange.bind(this), 1000, { leading: true });
    const typeConfig = config[props.type];
    const tinymceConfig = typeConfig
      ? typeConfig(
        props.themeEditorAvailable,
        props.adminAssetPath,
        this.onShowMediaLink,
        this.onShowLink,
        this.commitChange,
      ) : {};
    const showEditor = typeof typeConfig !== 'undefined';
    tinymceConfig.language = this.mapLanguage(currentBrowsboxLanguage || props.defaultLanguage.code || 'nl');
    this.state = {
      showEditor,
      tinymceConfig: {
        ...tinymceConfig,
        ...props.extraOptions,
      },
    };
  }

  componentDidMount() {
    this.onLanguageChange(i18n.language);
    i18n.on('languageChanged', this.onLanguageChange);
  }

  componentWillUnmount() {
    i18n.off('languageChanged', this.onLanguageChange);
  }

  onLanguageChange = (lang) => {
    const language = this.mapLanguage(lang);

    if (!language) {
      return;
    }

    const typeConfig = config[this.props.type];
    const showEditor = typeof typeConfig !== 'undefined';
    const tinymceConfig = typeConfig
      ? typeConfig(
        this.props.themeEditorAvailable,
        this.props.adminAssetPath,
        this.onShowMediaLink,
        this.onShowLink,
      ) : {};
    tinymceConfig.language = language;

    if (window.tinymce) {
      const tinymceI18n = window.tinymce?.util?.I18n;

      if (tinymceI18n.getCode() !== language) {
        tinymceI18n.setCode(language);
      }
    }

    this.setState(state => ({
      ...state,
      showEditor,
      tinymceConfig,
    }), () => {
      this.forceUpdate();
    });
  };

  // Based on events in webcreatorfunctions.editor.js tinymce.init
  onEditorKeydown() {
    if (this.props.shouldBroadCastUpdate) {
      this.props.editorUpdate();
    }
  }

  // Used by tinymce 'mediaLinkButton' plugin to show mediaManager modal
  onShowMediaLink = async (callback) => {
    const { canceled, media } = await Media.launchMediaLibraryAsync();

    if (canceled) {
      return;
    }

    callback(media);
  };

  // Used by tinymce 'linkButton' plugin to show linkPicker modal
  onShowLink = async (callback, selectedLink) => {
    const { lang } = this.context;
    const { canceled, link } = await Links.launchLinkPickerAsync({
      link: selectedLink,
      lang,
    });

    if (canceled) {
      return;
    }

    callback(link);
  };

  onEditorBlur(event) {
    this.commitChange(event.target);
  }

  onEditorChange(event) {
    this.commitChange(event.target);
  }

  mapLanguage = (lang) => {
    const map = {
      en: 'en_GB',
      nl: 'nl',
      pl: 'pl',
      fr: 'fr_FR',
    };

    return map[lang];
  };

  commitChange(editor) {
    // Tinymce getContent doesn't contain the style attribute, 'em' or 'strong'.
    // Use 'raw' format and strip tinymce specific attributes
    const rawContent = editor.getContent({ format: 'raw' });
    const content = sanatizeHtml(rawContent);
    if (content !== this.props.content) {
      this.props.onChange(content);
    }
  }

  render() {
    const {
      content,
      children,
      className,
    } = this.props;
    const {
      showEditor,
      tinymceConfig,
    } = this.state;
    if (showEditor) {
      return (
        <TinyMCE
          content={content}
          config={tinymceConfig}
          onKeydown={this.onEditorKeydown}
          onBlur={this.onEditorBlur}
          onChange={this.onEditorChange}
          className={className}
        />
      );
    }

    return children;
  }
}

const mapStateToProps = (state) => {
  const {
    pages: {
      settings: {
        themeEditorAvailable,
        adminAssetPath,
      },
    },
    global: {
      browsboxLanguages,
    },
  } = state;

  return {
    themeEditorAvailable,
    adminAssetPath,
    defaultLanguage: browsboxLanguages.find(lang => lang.default),
  };
};

const mapDispatchToProps = {
  editorUpdate,
};

Editor.propTypes = propTypes;
Editor.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Editor);
