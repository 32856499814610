import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { BbIconButton, BbMenu, BbMenuItem } from '../../@browsbox-ui';
import Icon from '../../Icon/Icon';
import { setSortDirection } from '../../../actions/media';

const MediaSortDirection = ({ value, onChange, t }) => {
  const [open, setOpen] = useState(false);

  const handleChange = (nextValue) => {
    onChange(nextValue);
    setOpen(false);
  };

  const options = [
    { value: 'name', icon: 'sort-alpha', label: 'MEDIA.sortName' },
    { value: 'size', icon: 'filter', label: 'MEDIA.sortSize' },
    { value: 'type', icon: 'file', label: 'MEDIA.sortType' },
  ];

  return (
    <div className="c-bb-media__sort-changer" onMouseLeave={() => setOpen(false)}>
      <BbIconButton onClick={() => setOpen(true)}>
        <Icon name="sort" />
      </BbIconButton>
      {open && (
        <BbMenu>
          {options.map(option => (
            <BbMenuItem
              key={option.value}
              onClick={() => handleChange(option.value)}
              selected={value === option.value}
              left={<Icon name={option.icon} />}
            >
              {t(option.label)}
            </BbMenuItem>
          ))}
        </BbMenu>
      )}
    </div>
  );
};

MediaSortDirection.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

MediaSortDirection.defaultProps = {
  value: null,
};

const mapStateToProps = state => ({
  value: state.media.sortDirection,
});

const mapDispatchToProps = {
  onChange: setSortDirection,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withNamespaces()(MediaSortDirection),
);
