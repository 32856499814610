import {
  MEDIA_SUCCESS,
  MEDIA_REMOVE_UPLOAD_ITEM,
  MEDIA_UPDATE_UPLOAD_PROGRESS,
  MEDIA_UPLOAD_SUCCESS,
  MEDIA_UPLOAD_ERROR,
  MEDIA_UPLOAD_FILE_START,
  MEDIA_LIBRARY_RESET_STATE,
  MEDIA_LIBRARY_SET_PAGE,
  MEDIA_LIBRARY_SET_SEARCH_PHRASE,
  MEDIA_LIBRARY_INCREASE_PAGE,
  MEDIA_REQUEST,
  MEDIA_LIBRARY_SET_FOLDER_ID,
  MEDIA_LIBRARY_CLEAR_FOLDER_ID,
  MEDIA_FOLDER_PATH_SUCCESS,
  MEDIA_LIBRARY_SET_VIEW_MODE,
  MEDIA_FOLDERS_REQUEST,
  MEDIA_FOLDERS_SUCCESS,
  MEDIA_FOLDERS_FAILURE,
  MEDIA_LIBRARY_SHOW_CREATE_FOLDER_MODAL,
  MEDIA_LIBRARY_HIDE_CREATE_FOLDER_MODAL,
  MEDIA_LIBRARY_SET_SORT_DIRECTION,
  searchMedia, RESET_SEARCH_VIEW,
} from '../actions/media';

const VIEW_MODE_KEY = 'bb_media_view_mode';
const enabledViewModes = [
  'grid',
  'table',
];

const resolveDefaultViewMode = () => {
  if (typeof localStorage !== 'undefined') {
    const stored = localStorage.getItem(VIEW_MODE_KEY);
    if (stored && enabledViewModes.includes(stored)) {
      return stored;
    }
  }

  return enabledViewModes[0];
};

export const initialState = {
  uploadQueue: {},
  folderId: null,
  breadcrumbs: [],
  externalSources: [],
  viewMode: resolveDefaultViewMode(),
  isFetchingFolders: false,
  isFetchingMedia: false,
  isCreateFolderModalVisible: false,
  library: {
    page: 1,
    searchPhrase: '',
    ids: [],
  },
  sortDirection: null,
  searchResult: {
    media: [],
    folders: [],
    isLoading: false,
  },
};

const media = (state = initialState, action) => {
  switch (action.type) {
    case MEDIA_UPLOAD_FILE_START: {
      const queue = { ...state.uploadQueue };
      const { file, uniqId } = action.value;

      queue[uniqId] = {
        startAt: (new Date()).getTime(),
        isUploading: true,
        uniqueId: uniqId,
        file,
        progress: 0,
      };

      return {
        ...state,
        uploadQueue: queue,
      };
    }
    case MEDIA_UPDATE_UPLOAD_PROGRESS: {
      const queue = { ...state.uploadQueue };
      const { file, progress, uniqId } = action.value;

      queue[uniqId] = {
        ...queue[uniqId],
        file,
        progress,
      };

      return {
        ...state,
        uploadQueue: queue,
      };
    }
    case MEDIA_UPLOAD_SUCCESS: {
      const queue = { ...state.uploadQueue };
      const { id } = action.response;
      const { uploadId } = action.metadata;
      const mediaItem = queue[uploadId];

      if (mediaItem) {
        delete queue[uploadId];
      }

      return {
        ...state,
        uploadQueue: queue,
        library: {
          ...state.library,
          ids: [
            ...state.library.ids,
            id,
          ],
        },
      };
    }
    case MEDIA_UPLOAD_ERROR: {
      const queue = { ...state.uploadQueue };
      const { uniqId, message } = action.value;

      if (queue[uniqId]) {
        queue[uniqId].error = message;
      }

      return {
        ...state,
        uploadQueue: queue,
      };
    }
    case MEDIA_REMOVE_UPLOAD_ITEM: {
      const queue = { ...state.uploadQueue };
      const { uniqId } = action.value;

      if (queue[uniqId]) {
        delete queue[uniqId];
      }

      return {
        ...state,
        uploadQueue: queue,
      };
    }
    case MEDIA_REQUEST: {
      return {
        ...state,
        isFetchingMedia: true,
        library: {
          ...state.library,
          page: 1,
          ids: [],
        },
      };
    }
    case MEDIA_SUCCESS: {
      const queue = { ...state.uploadQueue };
      Object.keys(queue).forEach((key) => {
        const item = queue[key];

        if (!item.isUploading) {
          delete queue[key];
        }
      });

      const { media: mediaEntities = {} } = action.response.entities;
      const ids = Object.keys(mediaEntities);

      return {
        ...state,
        isFetchingMedia: false,
        uploadQueue: queue,
        library: {
          ...state.library,
          ids,
        },
      };
    }
    case MEDIA_FOLDERS_REQUEST: {
      return {
        ...state,
        isFetchingFolders: true,
      };
    }
    case MEDIA_FOLDERS_SUCCESS: {
      return {
        ...state,
        isFetchingFolders: false,
      };
    }
    case MEDIA_FOLDERS_FAILURE: {
      return {
        ...state,
        isFetchingFolders: false,
        folderId: null,
      };
    }
    case MEDIA_LIBRARY_RESET_STATE: {
      return {
        ...state,
        library: initialState.library,
      };
    }
    case MEDIA_LIBRARY_INCREASE_PAGE: {
      return {
        ...state,
        library: {
          ...state.library,
          page: state.library.page + 1,
        },
      };
    }
    case MEDIA_LIBRARY_SET_PAGE: {
      const { page } = action.value;

      return {
        ...state,
        library: {
          ...state.library,
          page: +page,
        },
      };
    }
    case MEDIA_LIBRARY_SET_SEARCH_PHRASE: {
      const { phrase } = action.value;

      return {
        ...state,
        library: {
          ...state.library,
          page: 1,
          searchPhrase: phrase || '',
        },
      };
    }
    case MEDIA_LIBRARY_SET_FOLDER_ID: {
      const { id } = action.value;
      return {
        ...state,
        folderId: id,
      };
    }
    case MEDIA_LIBRARY_CLEAR_FOLDER_ID: {
      return {
        ...state,
        folderId: null,
      };
    }
    case MEDIA_FOLDER_PATH_SUCCESS: {
      const { response } = action;

      return {
        ...state,
        breadcrumbs: response || [],
      };
    }
    case MEDIA_LIBRARY_SET_VIEW_MODE: {
      const { mode } = action.value;

      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(VIEW_MODE_KEY, mode);
      }

      return {
        ...state,
        viewMode: mode,
      };
    }
    case MEDIA_LIBRARY_SHOW_CREATE_FOLDER_MODAL: {
      return {
        ...state,
        isCreateFolderModalVisible: true,
      };
    }
    case MEDIA_LIBRARY_HIDE_CREATE_FOLDER_MODAL: {
      return {
        ...state,
        isCreateFolderModalVisible: false,
      };
    }
    case MEDIA_LIBRARY_SET_SORT_DIRECTION: {
      return {
        ...state,
        sortDirection: action.value,
      };
    }
    case searchMedia.REQUEST: {
      return {
        ...state,
        searchResult: {
          isLoading: true,
        },
      };
    }
    case searchMedia.SUCCESS: {
      const { media: items, folders } = action.response;
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          isLoading: false,
          media: items,
          folders,
        },
      };
    }
    case RESET_SEARCH_VIEW:
    case searchMedia.FAILURE: {
      return {
        ...state,
        searchResult: {
          media: [],
          folders: [],
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default media;
