/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import AbstractItemView from './AbstractItemView';
import { bytesToSize } from '../../../tools/units';
import Icon from '../../Icon/Icon';
import MediaContextMenu from './MediaContextMenu';
import { BbIconButton } from '../../@browsbox-ui';

const propTypes = {
  id: PropTypes.number.isRequired,
  thumbnail: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  fileExtension: PropTypes.string.isRequired,
  updatedOn: PropTypes.string,
  resolution: PropTypes.string,
  size: PropTypes.number,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
};

const defaultProps = {
  resolution: '',
  updatedOn: '',
  size: '',
  isSelected: false,
  onDoubleClick: () => {},
};

const asDate = (date) => {
  if (Number.isNaN(Date.parse(date))) {
    return '';
  }

  return (new Date(date)).toLocaleDateString();
};

const MediaListViewItem = (props) => {
  const [open, setOpen] = React.useState(false);

  const showMenu = () => setOpen(true);
  const hideMenu = () => setOpen(false);

  const {
    id,
    fileExtension,
    filename,
    isSelected,
    resolution,
    updatedOn,
    thumbnail,
    size,
    onClick,
    onRename,
    onDelete,
    onDownload,
    onView,
    onMove,
  } = props;

  const thumbnailUrl = thumbnail || '/images/block/placehold500x280.png';

  return (
    <tr className="c-bb-media__table__item" onMouseLeave={hideMenu}>
      <td
        onClick={onClick}
        className="c-bb-media__table__item__image"
      >
        <div className="o-bb-media__thumbnail">
          <img src={thumbnailUrl} alt="" draggable="false" />
          {isSelected && (
            <div className="o-bb-media__active">
              <Icon name="check" style={{ fill: '#ddd' }} />
            </div>
          )}
        </div>
      </td>
      <td
        onClick={onClick}
        className="c-bb-media__table__item__title"
      >
        <h2 className="o-bb-media__title">{filename}{fileExtension}</h2>
      </td>
      <td>
        <div className="o-bb-media__meta">
          {updatedOn && asDate(updatedOn)} {resolution && (<span>|</span>)} {resolution || ''}
        </div>
      </td>
      <td>
        <div className="o-bb-media__size">
          {size && bytesToSize(size)}
        </div>
      </td>
      <td className="c-bb-media__table__item__actions">
        <BbIconButton onClick={showMenu} className="o-bb-media__action">
          <Icon name="context-menu" />
        </BbIconButton>
        {open && (
          <MediaContextMenu
            mediaId={id}
            onRename={onRename}
            onRemove={onDelete}
            onDownload={onDownload}
            onView={onView}
            onMove={onMove}
            onClose={hideMenu}
          />
        )}
      </td>
    </tr>
  );
};

MediaListViewItem.propTypes = propTypes;
MediaListViewItem.defaultProps = defaultProps;

export default props => (
  <AbstractItemView
    {...props}
    component={MediaListViewItem}
  />
);
