import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import PromptModal from '../../Modals/PromptModal';
import { loadMediaFolders, hideCreateFolderModal, createMediaFolder } from '../../../actions/media';

const FolderCreate = ({
  createFolder, onClose, parentId, t,
}) => {
  const handleCreate = async ({ value }) => {
    await createFolder({ name: value, parent: parentId });
    onClose();
  };

  return (
    <PromptModal
      icon="icon-basic_sheet"
      onCancel={onClose}
      onClose={onClose}
      onOk={handleCreate}
      submitButtonText={t('MEDIA.folderCreateButton')}
      title={t('MEDIA.folderCreateTitle')}
      valueMaxLength={100}
      label={t('MEDIA.folderCreateLabel')}
      showRequired={false}
    />
  );
};

FolderCreate.propTypes = {
  createFolder: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  parentId: PropTypes.number,
};

FolderCreate.defaultProps = {
  parentId: null,
};

const mapStateToProps = state => ({
  parentId: state.media.folderId,
});

const mapDispatchToProps = {
  createFolder: createMediaFolder,
  fetchFolders: loadMediaFolders,
  onClose: hideCreateFolderModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withNamespaces()(FolderCreate),
);
